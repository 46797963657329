export const media = {
  maxPhone: `(max-width: 370px)`,
  minPhone: `(min-width: 370px)`,
  maxSmartphone: `(max-width: 414px)`,
  minSmartphone: `(min-width: 414px)`,
  maxTablet: `(max-width: 860px)`,
  minTablet: `(min-width: 860px)`,
  maxNotebook: `(max-width: 1024px)`,
  minNotebook: `(min-width: 1024px)`,
  maxDesktop: `(max-width: 1200px)`,
  minDesktop: `(min-width: 1200px)`,
  maxScreen: `(max-width: 1460px)`,
  minScreen: `(min-width: 1460px)`,
}
