import { FlattenSimpleInterpolation } from 'styled-components'

export type Style = FlattenSimpleInterpolation | string

export type Styles = {
  [key: string]: Style
}

export function mapStyles(key: string | undefined, styles: Styles): Style {
  if (!key) {
    return ''
  }

  if (!(key in styles)) {
    throw new Error(`key ${key} not found in styles map ${JSON.stringify(styles)}`)
  }

  return styles[key]
}
