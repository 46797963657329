export const breakpoints = {
  minPhone: `(min-width: 370px)`,
  maxPhone: `(max-width: 370px)`,

  minTablet: `(min-width: 860px)`,
  maxTablet: `(max-width: 860px)`,

  minNotebook: `(min-width: 1024px)`,
  maxNotebook: `(max-width: 1024px)`,

  minDesktop: `(min-width: 1200px)`,
  maxDesktop: `(max-width: 1200px)`,

  minScreen: `(min-width: 1460px)`,
  maxScreen: `(max-width: 1460px)`,
}
