import { css } from 'styled-components'

import { mapStyles } from './utils'
import { media } from './media'

export type Margin = 'small' | 'medium' | 'large'

const marginStyles = {
  small: css`
    margin-bottom: 34px;

    @media ${media.minTablet} {
      margin-bottom: 68px;
    }
  `,
  medium: css`
    margin-bottom: 50px;

    @media ${media.minTablet} {
      margin-bottom: 100px;
    }
  `,
  large: css`
    margin-bottom: 123px;

    @media ${media.minTablet} {
      margin-bottom: 246px;
    }
  `,
}

export function margin(margin: Margin) {
  return mapStyles(margin, marginStyles)
}
