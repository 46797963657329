import { css } from 'styled-components'

import { mapStyles } from './utils'

type Color = 'light' | 'lighter'

const colorStyles = {
  light: css`
    background-color: var(--color-background-light);
  `,
  lighter: css`
    background-color: var(--color-background-lighter);
  `,
}

export function background(color: Color) {
  return mapStyles(color, colorStyles)
}
