import { css } from 'styled-components'

import { font } from './font'
import { breakpoints } from './config'

export function base() {
  return css`
    /** Properties */

    :root {
      --container-width: 1580px;
      --container-padding-x: 20px;
      --gap: 20px;
      --color-primary: #365675; // blue
      --color-secondary: #48a851; // green
      --color-background-light: rgba(54, 86, 117, 0.2);
      --color-background-lighter: rgba(54, 86, 117, 0.04);
    }

    @media ${breakpoints.minPhone} {
      :root {
        --container-padding-x: 30px;
      }
    }

    @media ${breakpoints.minTablet} {
      :root {
        --container-padding-x: 50px;
      }
    }

    /** Box-Sizing */

    html {
      box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    /** Body */

    body {
      margin: 0;
      color: var(--color-primary);
      ${font('body')}
    }

    h1,
    h2,
    h3 {
      font-family: 'Source Serif Pro', serif;
      font-weight: normal;
    }

    h1 {
      ${font('heading-1')}
    }

    h2 {
      ${font('heading-2')}
    }

    h3 {
      ${font('heading-3')}
    }

    a {
      cursor: pointer;
      color: inherit;
      transition: color 0.35s ease;
    }

    p {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    ul {
      padding-left: 20px;
    }

    html,
    body,
    #__next {
      height: 100%;
    }
  `
}
