import type { AppProps } from 'next/app'
import { createGlobalStyle } from 'styled-components'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { IntlProvider } from 'react-intl'

import '@csstools/normalize.css'
import '../base/fonts.css'
import { base } from '../base'

const locales: any = {
  de: require('../../locales/de.json'),
  en: require('../../locales/en.json'),
}

const GlobalStyles = createGlobalStyle`
  ${base()}
`

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const currentLocale: string = router.locale || 'de'

  return (
    <>
      <GlobalStyles />
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="apple-mobile-web-app-title" content="wetrust" />
        <meta name="application-name" content="wetrust" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="preload"
          href="/fonts/source-serif-pro-v11-latin-regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/AvenirNext/normal_normal_normal.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/AvenirNext/normal_normal_bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <IntlProvider messages={locales[currentLocale]} locale={currentLocale} defaultLocale={router.defaultLocale}>
        <Component {...pageProps} key={router.asPath} />
      </IntlProvider>
    </>
  )
}
