import { css } from 'styled-components'

export function flex() {
  return css`
    display: flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
  `
}

export function flexItem(width?: number, offset?: number) {
  return css`
    flex: auto;
    margin: var(--gap) 0 0 var(--gap);

    ${width && flexItemWidth(width)}

    // TODO: Calculate with margin from above.
    ${(offset || offset === 0) && flexItemOffset(offset)}
  `
}

export function flexItemWidth(width: number) {
  return css`
    flex: 0 0 ${(100 / 12) * width}%;
    max-width: ${(100 / 12) * width}%;
  `
}

export function flexItemOffset(width: number) {
  return css`
    margin-left: ${(100 / 12) * width}%;
  `
}
