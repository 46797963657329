import { css } from 'styled-components'

import { mapStyles } from './utils'
import { media } from './media'

type Variant = 'label' | 'body' | 'body-bold' | 'visual' | 'heading-1' | 'heading-2' | 'heading-3'

// TODO: A lot of repeaded styles in final CSS output...

const variantStyles = {
  label: css`
    font-family: 'AvenirNext', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 15px;

    @media ${media.minTablet} {
      font-size: 16px;
    }
  `,

  body: css`
    font-family: 'AvenirNext', Arial, Helvetica, sans-serif;
    font-weight: normal;
    line-height: 1.4;
    font-size: 18px;
  `,

  'body-bold': css`
    font-family: 'AvenirNext', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 1.4;
    font-size: 18px;
  `,

  visual: css`
    font-family: 'Source Serif Pro', serif;
    font-size: 42px;
    line-height: 1.18;

    @media ${media.minSmartphone} {
      font-size: 46px;
    }

    @media ${media.minTablet} {
      font-size: 72px;
    }
  `,

  'heading-1': css`
    font-family: 'Source Serif Pro', serif;
    font-size: 36px;
    line-height: 1.18;

    @media ${media.minTablet} {
      font-size: 48px;
    }
  `,

  'heading-2': css`
    font-family: 'Source Serif Pro', serif;
    font-size: 32px;
    line-height: 1.18;

    @media ${media.minTablet} {
      font-size: 40px;
    }
  `,

  'heading-3': css`
    font-family: 'Source Serif Pro', serif;
    font-size: 22px;
    line-height: 1.18;

    @media ${media.minTablet} {
      font-size: 32px;
      line-height: 1.3;
    }
  `,
}

export function font(variant: Variant) {
  return mapStyles(variant, variantStyles)
}
