import { css } from 'styled-components'

export function container() {
  return css`
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--container-padding-x);
    padding-right: var(--container-padding-x);

    width: var(--container-width);
    max-width: 100%;
  `
}
